import { NavigationItemParams } from '@/presentation/components/BottomNavigation/component';
import { NavigationIcon } from '@/presentation/components/BottomNavigation/components/NavigationIcon';
import { Anchor } from '@/presentation/components/BottomNavigation/style';
import { useOnboardingContextAlertRegister } from '@/presentation/hooks/useOnboardingContextAlertRegister';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { getWishlistUrl } from '@/presentation/services/urls';
import { Icon, Theme } from '@pelando/components';

import { Flag, Sparkles } from '@pelando/fontawesome/icons';

import Link from 'next/link';
import { useRef } from 'react';
import {
  FloatingElementVerticalPosition,
  FloatingElementHorizontalPosition,
} from '@pelando/components/components/FloatingElementWrapper/consts';
import { useRouter } from 'next/router';
import { TextIconTooltipStyle } from './style';

export function AlertsNavigationItem({
  onClick,
  active,
  count,
  hasContextAlertRegister,
}: NavigationItemParams & { hasContextAlertRegister: boolean }) {
  const { t } = useTranslation('header', 'onboardingContext');
  const { locale } = useRouter();
  const alertRef = useRef<HTMLDivElement>(null);
  const { isVisible } = useOnboardingContextAlertRegister(
    hasContextAlertRegister
  );

  // TODO: Incuindo o hasTag para tradução no tooltip gerou erro de hydration, verificar outra forma.

  return (
    <TextIconTooltipStyle
      className="tooltip"
      content={
        <p style={{ color: `rgb(${Theme.colors.Juliet})` }}>
          <strong>Crie um alerta</strong> para o produto que você procura!
        </p>
      }
      openTo={{
        vertical: FloatingElementVerticalPosition.TOP,
        horizontal: FloatingElementHorizontalPosition.MIDDLE,
      }}
      childrenRef={alertRef}
      icon={
        <Icon
          style={{ fontSize: 24, color: `rgb(${Theme.colors.FoxTrot})` }}
          icon={Sparkles}
        />
      }
      isVisible={isVisible}
    >
      <div ref={alertRef}>
        <Link
          href={getWishlistUrl(locale)}
          passHref
          prefetch={false}
          legacyBehavior
        >
          <Anchor onClick={onClick} aria-current={active}>
            <NavigationIcon icon={Flag} count={count} />
            <span>{t('alerts-link-text')}</span>
          </Anchor>
        </Link>
      </div>
    </TextIconTooltipStyle>
  );
}
