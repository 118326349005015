import {
  getOnboardingStageLocalStorage,
  removeTooltipOnboardingAlertHomeLocalStorage,
} from '@/infra/storage/onboardingManagement';
import { isOnboardingContextAlertRegister } from '@/presentation/services/featureFlags';
import { useRouter } from 'next/router';

import { useState, useEffect } from 'react';

export function useOnboardingContextAlertRegister(
  hasContextAlertRegister: boolean
) {
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const onboardingFinished = !getOnboardingStageLocalStorage();

  const isContextAlertRegisterEnabled = isOnboardingContextAlertRegister();
  const isHome = router.pathname === '/[slug]';
  const hasTooltip =
    isContextAlertRegisterEnabled &&
    hasContextAlertRegister &&
    isHome &&
    onboardingFinished;

  useEffect(() => {
    if (hasTooltip) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
        removeTooltipOnboardingAlertHomeLocalStorage();
      }, 5000);
    } else {
      setIsVisible(false);
    }
  }, [hasTooltip]);

  return { isVisible };
}
